import React, {Fragment} from 'react';
import useDarkMode from 'use-dark-mode';

// import Toggle from '../components/Toggle';
import Switch from 'react-switch';
import {IconContext} from 'react-icons';
import {IoIosMoon,IoIosSunny} from 'react-icons/io';

const DarkModeToggle = () => {
  const darkMode = useDarkMode(false);

  return (
    <Fragment>
      {/* <button type="button" onClick={darkMode.disable}>
        ☀
      </button>
      <Toggle checked={darkMode.value} onChange={darkMode.toggle} />
      <button type="button" onClick={darkMode.enable}>
        ☾
      </button> */}
      <IconContext.Provider value={{ size: "100%", color:"#ffffff"}}>
      <Switch 
        checked={darkMode.value} 
        onChange={darkMode.toggle}
        checkedIcon={<IoIosSunny />}
        uncheckedIcon={<IoIosMoon />}
        // onColor="#333"
        // offColor="#999"
        onColor="#333"
        offColor="#666"
        height={20}
        width={40}
        className="content-center order-2 mx-2 md:order-4 md:mr-0 md:ml-8"
        aria-label="dark-mode-switch"
        />
        </IconContext.Provider>
    </Fragment>
  );
};

export default DarkModeToggle;
import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import {IoIosPulse,IoIosMenu,IoIosCloseCircleOutline} from "react-icons/io";
import DarkModeToggle from "../components/darkMode";

class Checked extends React.Component {
  render() {
      return (
          <svg width="24" fill="#00ea00" height="24" viewBox="0 0 24 24">
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
          </svg>
      );
  }
}

class CheckBox extends React.Component {
  render() {
      return (
          <svg width="24" height="24" fill="#666666" viewBox="0 0 24 24">
              <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
          </svg>
      );
  }
}

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  const { site } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `);

  return (
    // <header className="border-b border-gray-200">
      <header 
          // className="fixed z-50 w-screen grau-header pin-l pin-x">
        className={ `${
            isExpanded ? `header-open` : `header-closed`
          } fixed z-50 w-screen pin-l pin-x
          `} >
      {/* <div className="flex flex-wrap items-center content-center justify-between order-1 p-2 mx-auto text-lg sm:text-xl md:p-4 sm:order-1 "> */}
      <div className="flex flex-wrap items-center content-center justify-between order-1 w-full rfs-p-6 rfs-mx-auto rfs-text-lg sm:order-1">
        <Link className="flex items-center flex-grow no-underline rfs-pl-2 logo" to="/">
            {/* <IoIosPulse className="primary-color"/> */}
          <h1 className="inline-block rfs-m-1 rfs-ml-0 rfs-text-3xl">
            Thomas Grau.
          </h1>
          {/* <span className="text-base font-bold uppercase sm:text-xl logo-thomas">
            thomas
          </span>
          <span className="text-base font-bold uppercase sm:text-xl logo-grau">
            grau
          </span> */}
        </Link>

        <button
          className="flex items-center order-3 block rounded rfs-text-2xl rfs-px-3 rfs-py-2 md:hidden md:order-2"
          aria-label="Menu"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          {/* <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg> */}
          {(isExpanded ? <IoIosCloseCircleOutline /> : <IoIosMenu />)}
        </button>
        
        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          //} md:block md:flex md:items-center w-full md:w-auto items-end order-4 md:order-3`}
          } flex h-minus w-full order-4 md:order-3
            items-center flex-col justify-center rfs-text-4xl font-bold
            uppercase
            md:font-medium md:rfs-text-base
            md:flex-row
            md:items-end
            md:block md:flex md:items-center md:w-auto 
          `}
        >
          {[
            {
              route: `/`,
              title: `Home`
            },
            // {
            //   route: `/blog/`,
            //   title: `Blog`
            // },
            {
              route: `/impressum`,
              title: `Impressum`
            }
          ].map(link => (
            <Link
              className="block text-center rfs-mt-4 nav-top md:inline-block md:rfs-mt-0 md:rfs-px-4"
              key={link.title}
              to={link.route}
              activeClassName="nav-top-active"
            >
              <span className="">{link.title}</span>
            </Link>
          ))}
        </nav>

        <DarkModeToggle />

      </div>
    </header>
  );
}

export default Header;
